@import './_reset';

:root {
  --scroll-bar-track-color: #fff;
  --scroll-bar-thumb-color: #ccc;
}

@font-face {
  font-family: 'Golos';
  font-weight: 400;
  font-style: normal;

  src: url('shared/assets/fonts/GolosText-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Golos';
  font-weight: 500;
  font-style: normal;

  src: url('shared/assets/fonts/GolosText-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Golos';
  font-weight: 600;
  font-style: normal;

  src: url('shared/assets/fonts/GolosText-SemiBold.ttf') format('truetype');
  font-display: swap;
}

input[type='number'] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-thumb-color);
  border-radius: 4px;
  border: 2px solid var(--scroll-bar-track-color);
}

::-webkit-scrollbar-track {
  background-color: var(--scroll-bar-track-color);
}
